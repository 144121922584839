<template>
  <Browse
    :columns="[
      'name',
      'precio',
      'unit',
      'description',
      'department',
      'barcode',
      'tax_rate',
    ]"
    :formats="{
      precio: 'Money',
    }"
    itemsPerPage="10"
    :searchOnStart="true"
    table="products"
  />
</template>

<script>
import Browse from "@/components/Browse.vue";

export default {
  components: {
    Browse,
  },
};
</script>
