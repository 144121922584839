var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Browse',{attrs:{"columns":[
    'name',
    'precio',
    'unit',
    'description',
    'department',
    'barcode',
    'tax_rate' ],"formats":{
    precio: 'Money',
  },"itemsPerPage":"10","searchOnStart":true,"table":"products"}})}
var staticRenderFns = []

export { render, staticRenderFns }